/**
 * integrator.v2.config
 * API конфигуратора
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdapterDto } from './adapterDto';
import { LoggerDto } from './loggerDto';
import { OutgoingAdapterDto } from './outgoingAdapterDto';
import { TranslatorDto } from './translatorDto';
import { IncomingAdapterDto } from './incomingAdapterDto';


export interface ServiceDto { 
    /**
     * недоступен, отключен, включается, работает, выключается, останавлен, ошибка
     */
    status?: ServiceDto.StatusEnum;
    /**
     * признак использования сервиса
     */
    isEnabled?: boolean;
    /**
     * сервис реализует стандартный интерфейс конфигурации
     */
    isTemplate?: boolean;
    /**
     * сервис сущесвует внешняя маршрутизация для MessageApi
     */
    hasIngress?: boolean;
    /**
     * интервал опроса/отправки в миллисекундах
     */
    period?: number;
    /**
     * время ожидания перед повторным запросом при ошибке процессинга
     */
    timeoutOnError?: number;
    /**
     * признак остановки процесса при ошибке процессинга
     */
    stopOnError?: boolean;
    /**
     * Уровень логирования событий модуля
     */
    logLevel?: string;
    /**
     * Список логеров
     */
    loggers?: Array<LoggerDto>;
    /**
     * Список входящих адаптеров
     */
    incomingAdapters?: Array<IncomingAdapterDto>;
    /**
     * Список трансляторов
     */
    translators?: Array<TranslatorDto>;
    errorAdapter?: AdapterDto;
    outgoingAdapters?: Array<OutgoingAdapterDto>;
    /**
     * Связанный процессор (readonly)
     */
    processorUuid?: string;
    /**
     * Связанный роутер (readonly)
     */
    routerUuid?: string;
    /**
     * Уникальный идентификатор
     */
    uuid?: string;
    /**
     * Уникальное отображаемое название
     */
    title?: string;
    /**
     * Уникальное имя сущности
     */
    name?: string;
    /**
     * Комментарии
     */
    description?: string;
    /**
     * Дополнительные параметры сущности в JSON
     */
    options?: object;
    /**
     * Время и дата архивации сущности, у активной сущности установлена в null
     */
    archivedAt?: string;
    /**
     * Пользователь выполнивший архивацию сущности, у активной сущности - null
     */
    archivedBy?: string;
    /**
     * Время и дата создания сущности
     */
    createdAt?: string;
    /**
     * Идентификатор пользователя создавшего сущность
     */
    createdBy?: string;
    /**
     * Время и дата последнего обновления сущности
     */
    updatedAt?: string;
    /**
     * Идентификатор пользователя обновившего сущность
     */
    updatedBy?: string;
}
export namespace ServiceDto {
    export type StatusEnum = 'unavailable' | 'disabled' | 'started' | 'running' | 'shutdown' | 'stopped' | 'error';
    export const StatusEnum = {
        Unavailable: 'unavailable' as StatusEnum,
        Disabled: 'disabled' as StatusEnum,
        Started: 'started' as StatusEnum,
        Running: 'running' as StatusEnum,
        Shutdown: 'shutdown' as StatusEnum,
        Stopped: 'stopped' as StatusEnum,
        Error: 'error' as StatusEnum
    };
}


