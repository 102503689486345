/**
 * integrator.v2.config
 * API конфигуратора
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * тип элемента иерархии меню
 */
export type ItemType = 'adapters-group' | 'incoming-adapters-group' | 'outgoing-adapters-group' | 'adapters' | 'incoming-adapters' | 'outgoing-adapters' | 'routers-group' | 'channels' | 'channels-group' | 'commands' | 'commands-group' | 'conditions' | 'conditions-group' | 'credentials' | 'credentials-group' | 'loggers' | 'loggers-group' | 'processors' | 'processors-group' | 'projects' | 'projects-group' | 'services' | 'services-group' | 'routers' | 'translators' | 'translators-group';

export const ItemType = {
    AdaptersGroup: 'adapters-group' as ItemType,
    IncomingAdaptersGroup: 'incoming-adapters-group' as ItemType,
    OutgoingAdaptersGroup: 'outgoing-adapters-group' as ItemType,
    Adapters: 'adapters' as ItemType,
    IncomingAdapters: 'incoming-adapters' as ItemType,
    OutgoingAdapters: 'outgoing-adapters' as ItemType,
    RoutersGroup: 'routers-group' as ItemType,
    Channels: 'channels' as ItemType,
    ChannelsGroup: 'channels-group' as ItemType,
    Commands: 'commands' as ItemType,
    CommandsGroup: 'commands-group' as ItemType,
    Conditions: 'conditions' as ItemType,
    ConditionsGroup: 'conditions-group' as ItemType,
    Credentials: 'credentials' as ItemType,
    CredentialsGroup: 'credentials-group' as ItemType,
    Loggers: 'loggers' as ItemType,
    LoggersGroup: 'loggers-group' as ItemType,
    Processors: 'processors' as ItemType,
    ProcessorsGroup: 'processors-group' as ItemType,
    Projects: 'projects' as ItemType,
    ProjectsGroup: 'projects-group' as ItemType,
    Services: 'services' as ItemType,
    ServicesGroup: 'services-group' as ItemType,
    Routers: 'routers' as ItemType,
    Translators: 'translators' as ItemType,
    TranslatorsGroup: 'translators-group' as ItemType
};

