/**
 * integrator.v2.config
 * API конфигуратора
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Событие в журнале
 */
export interface EventLogDto { 
    /**
     * Идентификатор события
     */
    id?: number;
    /**
     * Альтернативный идентификатор события
     */
    uuid?: string;
    /**
     * Уровень логирования которому соответствует событие
     */
    level?: EventLogDto.LevelEnum;
    /**
     * _
     */
    status?: string;
    /**
     * Название события
     */
    eventName?: string;
    /**
     * Сервис - Идентификатор сервиса, где сформировалось событие
     */
    serviceName?: string;
    /**
     * Модуль - Идентификатор блока, где сформировалось событие
     */
    moduleName?: string;
    /**
     * Операция
     */
    operationName?: string;
    /**
     * Объект
     */
    objectName?: string;
    /**
     * КШД ID - Идентификатор сообщения (в КШД)
     */
    dataId?: number;
    /**
     * Список тегов системы источника сообщения, через запятую
     */
    source?: string;
    /**
     * Идентификатор сообщения в системе источнике
     */
    sourceId?: string;
    /**
     * Список тегов целевой системы для сообщения, через запятую
     */
    target?: string;
    /**
     * Идентификатор сообщения в целевой системе
     */
    targetId?: string;
    /**
     * Идентификатор сообщения
     */
    messageUuid?: string;
    /**
     * Служебный заголовок сообщения в JSON
     */
    headers?: object;
    /**
     * Сериализованное представление сообщения
     */
    message?: string;
    description?: string;
    error?: string;
    errorCode?: string;
    createdAt?: string;
    archivedAt?: string;
}
export namespace EventLogDto {
    export type LevelEnum = 'all' | 'trace' | 'debug' | 'info' | 'warning' | 'error';
    export const LevelEnum = {
        All: 'all' as LevelEnum,
        Trace: 'trace' as LevelEnum,
        Debug: 'debug' as LevelEnum,
        Info: 'info' as LevelEnum,
        Warning: 'warning' as LevelEnum,
        Error: 'error' as LevelEnum
    };
}


